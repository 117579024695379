'use client';

export function Btn({
  children,
  onClick
}: {
  children: React.ReactNode;
  onClick: (event: any) => void;
}) {
  return <button onClick={evt => {
    onClick(evt);
  }} className="h-[48px] border-key-color border-[1px] text-key-color hover:bg-[var(--focus-bg-color)] text-[14px] font-bold flex justify-center max-w-[348px] w-full items-center gap-1" data-sentry-component="Btn" data-sentry-source-file="Btn.tsx">
            {children}
        </button>;
}